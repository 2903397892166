export function flashMessageReset() {
    const flashes = Array.from(document.getElementsByClassName('flash_message'));
    if (flashes.length > 0) {
        console.log(flashes);
        flashes.forEach((element) => {
            setTimeout(function () {
                $(element).addClass('fadeout');
            }, 5000);
        });
    }
}
